<template>
  <v-navigation-drawer
    v-if="!conditionalDisplay.embedded"
    v-show="hasSideBar"
    v-model="show"
    :app="hasSideBar"
    :permanent="permanent"
    clipped
    left
    :touchless="!canSwipe"
    :mini-variant="minimized"
    :mobile-break-point="960"
    color="primary"
    overflow-hidden
    fill-height
  >
    <v-layout column fill-height>
      <v-flex shrink>
        <v-layout row justify-center align-center>
          <v-flex v-if="!minimized" text-xs-center title v-text="title"></v-flex>
          <v-flex v-if="canMinimize" shrink>
            <v-layout justify-right>
              <v-btn icon @click="miniMaximize">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon color="secondary" transition="fade-transition" v-on="on">{{
                      minimized ? "chevron_right" : "chevron_left"
                    }}</v-icon>
                  </template>
                  <span>{{
                    minimized ? $t("actions.maximize") : $t("actions.minimize")
                  }}</span>
                </v-tooltip>
              </v-btn>
            </v-layout>
          </v-flex>
          <v-flex v-if="canClose" shrink>
            <v-layout justify-right>
              <v-btn icon @click="hideSideBar()">
                <v-icon color="secondary">close</v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
      <v-flex grow scroll-y style="height: 0">
        <v-layout column align-start fill-height justify-center>
          <!-- width: 100% because of flex direction being set to colum to respect global components flow -->
          <!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
          <v-flex ref="sideBarContainer" grow style="width: 100%; height: 0" />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import AppModuleGuard from "@/mixins/ModulesGuards/AppModuleGuard";
import { mapMutations, mapState } from "vuex";

export default {
  name: "SideBar",
  mixins: [AppModuleGuard],
  data: function () {
    return {
      canMinimize: false,
      canClose: false,
      title: "",
      exist: false,
      registeredContent: null,
      lastEmitter: null,
    };
  },
	computed: {
		...mapState({
			hasSideBar: state => state.sideBar.hasSideBar,
			minimized: state => state.sideBar.minimized,
			showState: state => state.sideBar.show,
			permanent: state => state.sideBar.permanent,
		}),
		canSwipe() {
			return this.hasSideBar;
		},
		show: {
			get() {
				return this.showState;
			},
			set(value) {
				if (!this.hasSideBar) {
					this.showSidebar(false);
					return;
				}
				if (this.permanent && value) {
					return;
				}
				this.showSidebar(value);
			}
		},
  },
	created() {
		this.showSidebar(this.$vuetify.breakpoint.smAndUp);
	},
  methods: {
	  ...mapMutations({
		  setHasSideBar: 'sideBar/setHasSideBar',
		  showSidebar: 'sideBar/setShow',
		  setMinimized: 'sideBar/setMinimized',
		  setPermanent: 'sideBar/setPermanent',
		  setShowSideBar: 'sideBar/setShow',
	  }),
    getAppEventsActionsMapping: function () {
      return [
        { event: this.appEvents.SHOW_SIDEBAR, action: this.showSideBar },
        { event: this.appEvents.HIDE_SIDEBAR, action: this.hideSideBar },
        { event: this.appEvents.MINIMIZE_SIDEBAR, action: this.minimizeSideBar },
        { event: this.appEvents.MAXIMIZE_SIDEBAR, action: this.maximizeSideBar },
        { event: this.appEvents.SET_SIDEBAR_CONTENT, action: this.addSideBarContent },
        { event: this.appEvents.CLEAR_SIDEBAR_CONTENT, action: this.clearSideBarContent },
      ];
    },
    miniMaximize: function () {
			this.minimized
					? this.maximizeSideBar()
					: this.minimizeSideBar();
    },
    minimizeSideBar: function () {
			this.setMinimized(true);
      this.appEventBus.emit(this.appEvents.SIDEBAR_MINIMIZED);
    },
	  maximizeSideBar: function() {
		  this.setMinimized(false);
		  this.appEventBus.emit(this.appEvents.SIDEBAR_MAXIMIZED);
	  },
    showSideBar: function () {
      this.setShowSideBar(true);
    },
	  hideSideBar: function() {
		  this.setShowSideBar(false);
    },
    addSideBarContent: function (event) {
      if (!event.emitter) {
        throw new Error('Missing "emitter" parameter for SET_SIDEBAR_CONTENT event');
      } else if (!event.moduleInstance) {
        throw new Error('Missing "moduleInstance" parameter');
      }
      if (this.lastEmitter && this.lastEmitter !== event.emitter) {
        this.clearSideBarContent(this.lastEmitter);
      }
      this.lastEmitter = event.emitter;
      this.canMinimize = event.canMinimize !== undefined ? event.canMinimize : false;
	    if (!this.canMinimize) {
		    this.setMinimized(false);
	    }
	    this.canClose = event.canClose !== undefined ? event.canClose : false;
      this.title = event.title;
      if (this.registeredContent) {
        this.clearSideBarContent(this.lastEmitter);
      }
			this.setHasSideBar(true);
      this.registeredContent = event.moduleInstance;
      this.registeredContent.mini = this.minimized && this.canMinimize;
      this.registeredContent.$mount();
      if (this.$refs.sideBarContainer) {
        this.$refs.sideBarContainer.appendChild(this.registeredContent.$el);
      } else {
        this.$nextTick(() => {
          this.$refs.sideBarContainer?.appendChild(this.registeredContent.$el);
        });
      }
      if (event.startHidden !== undefined && event.startHidden === true) {
        this.show = false;
      }
    },
    clearSideBarContent: function (emitter) {
      if (!emitter) {
        throw new Error(
          'Missing "this" as argument when emitting CLEAR_SIDEBAR_CONTENT event'
        );
      }
      if (this.lastEmitter && this.lastEmitter === emitter) {
        if (this.registeredContent) {
          if (this.$refs.sideBarContainer) {
            this.$refs.sideBarContainer.removeChild(this.registeredContent.$el);
          }
          this.registeredContent.$destroy();
          this.registeredContent = null;
        }
        this.show = false;
      }
    },
  },
};
</script>
